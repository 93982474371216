<template>
  <div>
    <div class="commontop">
      <div class="course-infos-top" style="
          background-image: url(//img.mukewang.com/szimg/61d7e81e0840f19132000460.jpg);
        ">
        <div class="info-wrap">
          <div class="path">
            <a href="#/">首页</a><i class="icon-right2"></i><a href="/">实战</a><i class="icon-right2"></i>
            <span>{{ itemContent.title }}</span>
            <span> </span>
          </div>
          <div class="extra">
            <div class="course-collect clearfix js-course-collect l"><i class="imv2-star j"></i><span>收藏</span></div>
          </div>
          <div class="title-box">
            <h1>{{ itemContent.title }}</h1>
            <h2>{{ itemContent.content }}</h2>
          </div>
        </div>
        <div class="fixed-wrap" style="bottom: -64px">
          <div class="timeout-bigactivity js-timeout-bigactivity" data-endtime="" data-time="1644215452"></div>
          <div class="con">
            <div class="time-price clearfix">
              <div class="discount clearfix l">
                <div class="sale-timer l clearfix"><i class="name l">限时优惠</i></div>
                <div class="sale-price l">￥{{ itemContent.prire }}</div>
                <div class="ori-price l">原价￥{{ itemContent.unprire }}</div>
              </div>
              <div class="can-huabei l"><a href="#/help" target="_blank">花呗付款</a></div>
              <div class="can-baitiao l">
                <a href="#/help" target="_blank">京东白条</a>
              </div>
            </div>
            <div class="info-bar clearfix" style="margin-top: 0px">
              <span>难度</span>
              <span class="nodistance">初级</span>
              <i class="imv2-dot_samll"></i>
              <span>时长</span>
              <span class="nodistance">{{ itemContent.date }}小时</span>
              <i class="imv2-dot_samll"></i>
              <span>学习人数</span>
              <span class="nodistance">{{ itemContent.unm }}</span>
              <i class="imv2-dot_samll"></i>
              <span>综合评分</span>
              <span class="nodistance">{{ itemContent.pf }}</span>
            </div>
            <div class="btns js-btns" data-pay="0">
              <a href="javascript:;" class="js-buy-trigger red-btn gobuy" id="buy-trigger" @click="buy()">立即购买</a>
              <a href="javascript:;" class="js-addcart addcart" @click="addCart">加购物车</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="commonnav">
      <ul class="common-tab sem-common-tab1">
        <li v-for="(v, i) in ['课程介绍', '内容安排', '学习服务', '用户评价']" :key="i" :class="{ on: actives == v }" @click="actives = v" class="hover"><a target="_self">{{ v }}</a><div class="borderLine"></div></li>
      </ul>
    </div>
    <canner-1 v-html="itemContent.kc" v-show="actives == '课程介绍'" />
    <canner-2 :nrap="itemContent.nrap" v-show="actives == '内容安排'" />
    <canner-3 v-show="actives == '学习服务'" />
    <canner4 :oder="itemContent.oder" v-show="actives == '用户评价'" />
  </div>
</template>

<script>
import canner1 from "../../components/learnserve/item1.vue";
import canner2 from "../../components/learnserve/item2.vue";
import canner3 from "../../components/learnserve/item3.vue";
import canner4 from "../../components/learnserve/item4.vue";
import api from '../../ulti/ajax';
export default{
  data() {
    return {
      actives: "内容安排",
      itemContent: {},
    };
  },
  methods: {
    async buy() {
      console.log(this.$route.query.id);
      let CartId =(await api.buy(this.$route.query.id)).severId;
      window.location.href = '#/cartOder?id='+CartId;
    },
   async addCart() {
     let data = await api.AddCart(this.$route.query.id);
    if(data.message == '加入成功'){
      // alter
    }
    },
  },
  async created() {
    this.itemContent = (await api.getLearnserve()).data;
  },
  components: {
    canner2,
    canner1,
    canner3,
    canner4,
  },
};
</script>

<style scoped>
/* @import url("../../assets/css/learnserve.css"); */
.hover {
  cursor: pointer;
}
.commontop {
  background-image: url(https://class.imooc.com/static/module/marketpage2020/img/intro/fe2021-bg.png);
  height: 400px;
}
.commonnav {
  background: transparent;
}

.course-infos-top {
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.course-infos-top.black .path a,
.course-infos-top.black .path i,
.course-infos-top.black .path span {
  color: rgba(28, 31, 33, 0.6) !important;
}

.course-infos-top.black .path a:hover {
  color: #000 !important;
}

.course-infos-top.black .path a:hover i {
  color: #000 !important;
}

.course-infos-top.black .title-box h1,
.course-infos-top.black .title-box h2 {
  color: #000 !important;
  text-shadow: none !important;
}

.course-infos-top.black .extra .course-collect i,
.course-infos-top.black .extra .course-collect span {
  color: rgba(28, 31, 33, 0.4) !important;
}

.course-infos-top.black .extra .course-collect i:hover,
.course-infos-top.black .extra .course-collect span:hover {
  color: #000 !important;
}

.course-infos-top.black .extra .share {
  color: rgba(28, 31, 33, 0.4) !important;
}

.course-infos-top.black .extra .share:hover {
  color: #000 !important;
}

.course-infos-top .info-wrap {
  width: 1152px;
  margin: 0 auto;
  padding-top: 72px;
  padding-bottom: 136px;
  position: relative;
}

.course-infos-top .info-wrap .path {
  position: absolute;
  top: 16px;
  font-size: 12px;
  line-height: 24px;
}

.course-infos-top .info-wrap .path .split {
  color: rgba(255, 255, 255, 0.6);
  padding: 0 4px;
  font-style: normal;
}

.course-infos-top .info-wrap .path span {
  color: rgba(255, 255, 255, 0.6);
}

.course-infos-top .info-wrap .path a {
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.course-infos-top .info-wrap .path a i {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  line-height: 24px;
  position: relative;
  top: 4px;
}

.course-infos-top .info-wrap .path a:hover {
  color: #fff;
}

.course-infos-top .info-wrap .extra {
  position: absolute;
  right: 0;
  top: 12px;
}

.course-infos-top .info-wrap .extra .course-collect {
  margin-right: 36px;
  cursor: pointer;
}

.course-infos-top .info-wrap .extra .course-collect i,
.course-infos-top .info-wrap .extra .course-collect span {
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  float: left;
  color: rgba(255, 255, 255, 0.4);
  line-height: 32px;
}

.course-infos-top .info-wrap .extra .course-collect span {
  font-size: 12px;
  margin-left: 4px;
}

.course-infos-top .info-wrap .extra .course-collect i {
  font-size: 20px;
}

.course-infos-top .info-wrap .extra .course-collect:hover i,
.course-infos-top .info-wrap .extra .course-collect:hover span {
  color: #fff;
}

.course-infos-top .info-wrap .extra .share-action {
  border-radius: 16px;
  padding-right: 14px;
  height: 32px;
  cursor: pointer;
  font-size: 0;
}

.course-infos-top .info-wrap .extra .share-action.bg {
  padding-left: 2px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.6) 0,
    rgba(255, 255, 255, 0.1) 100%
  );
}

.course-infos-top .info-wrap .extra .share-action .share-money {
  background-color: #f76b1c;
  background-image: linear-gradient(270deg, #c11782 0, #f76b1c 100%);
  border-radius: 16px 16px 16px 0;
  font-size: 12px;
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  line-height: 32px;
  padding: 0 12px;
  display: inline-block;
}

.course-infos-top .info-wrap .extra .share-action .share {
  display: inline-block;
  float: none;
  padding: 0;
  line-height: 32px;
  margin: 0 0 0 12px;
  width: auto;
  height: auto;
  vertical-align: top;
  background-image: none;
  font-size: 20px;
  background-repeat: no-repeat;
  color: rgba(255, 255, 255, 0.8);
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.course-infos-top .info-wrap .extra .share-action .share:hover {
  color: #fff;
}

.course-infos-top .info-wrap .title-box {
  width: 1000px;
  margin: 0 auto;
}

.course-infos-top .info-wrap .title-box h1 {
  text-align: center;
  font-size: 40px;
  color: #fff;
  line-height: 48px;
  text-shadow: 0 2px 4px rgba(28, 31, 33, 0.6);
}

.course-infos-top .info-wrap .title-box h2 {
  text-align: center;
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  text-shadow: 0 2px 4px rgba(28, 31, 33, 0.6);
  margin-top: 8px;
}

.course-infos-top .fixed-wrap {
  width: 1152px;
  min-height: 128px;
  position: absolute;
  left: 50%;
  margin-left: -576px;
  bottom: -64px;
  background-image: url(https://coding.imooc.com/static/module/img/fixed_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 18px;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.1);
}

.course-infos-top .fixed-wrap.bigactivity {
  height: 169px;
  background-image: url(/static/module/img/fixed_bg_20211111.png?t=1);
  bottom: -64px;
  padding-top: 36px;
  box-sizing: border-box;
  box-shadow: 0 16px 16px 0 rgba(28, 31, 33, 0.1);
}

.course-infos-top .fixed-wrap.bigactivity .timeout-bigactivity {
  position: absolute;
  height: 20px;
  right: 29px;
  top: 17px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.course-infos-top .fixed-wrap.bigactivity .timeout-bigactivity .desc {
  font-size: 14px;
  color: #fff;
  line-height: 20px;
  font-weight: 700;
  white-space: nowrap;
  margin-right: 7px;
}

.course-infos-top .fixed-wrap.bigactivity .timeout-bigactivity .time-box {
  background: #fff;
  border-radius: 4px;
  font-size: 12px;
  color: #f2310d;
  text-align: center;
  line-height: 20px;
  width: 24px;
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
}

.course-infos-top .fixed-wrap.bigactivity .timeout-bigactivity .time-box.day {
  width: 26px;
}

.course-infos-top
  .fixed-wrap.bigactivity
  .timeout-bigactivity
  .time-box:not(:last-child) {
  margin-right: 6px;
}

.course-infos-top
  .fixed-wrap.bigactivity
  .timeout-bigactivity
  .time-box:not(:last-child)::after {
  content: ":";
  color: #fff;
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  right: -5px;
  top: 0;
}

.course-infos-top .fixed-wrap.bigactivity .con .time-price {
  margin-bottom: 12px;
}

.course-infos-top .fixed-wrap.bigactivity .con .can-huabei {
  background-image: none !important;
  background: #ff7224;
}

.course-infos-top .fixed-wrap.bigactivity .con .info-bar span {
  color: #ca5b1b;
}

.course-infos-top .fixed-wrap.bigactivity .con .info-bar i {
  color: #ca5b1b;
}

.course-infos-top .fixed-wrap.bigactivity .con .no-discount .ori-price {
  margin-left: 0;
}

.course-infos-top .fixed-wrap.bigactivity .con .btns {
  bottom: 40px;
  right: 64px;
  left: auto;
  top: auto;
}

.course-infos-top .fixed-wrap.bigactivity .con .btns .btn-box {
  width: 140px;
  height: 48px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}

.course-infos-top .fixed-wrap.bigactivity .con .btns .btn-box .btn-buy {
  width: 140px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background: #f24e4b;
  border-radius: 24px;
  font-size: 16px;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  position: relative;
  display: inline-block;
}

.course-infos-top
  .fixed-wrap.bigactivity
  .con
  .btns
  .btn-box
  .btn-buy
  .coupon-text {
  position: absolute;
  top: -12px;
  right: 0;
  padding: 2px 8px;
  box-sizing: border-box;
  white-space: nowrap;
  background: #ffde7e;
  border-radius: 12px 12px 12px 0;
  border: 1px solid #fff;
  font-size: 12px;
  color: #f20d0d;
  letter-spacing: 0;
  line-height: 18px;
  font-weight: 500;
}

.course-infos-top .fixed-wrap .con {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 32px 40px;
  box-sizing: border-box;
}

.course-infos-top .fixed-wrap .con .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  background-image: url(/static/module/img/renew.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.course-infos-top .fixed-wrap .con .timer {
  font-size: 14px;
  color: #545c63;
  letter-spacing: 0;
  text-align: left;
  line-height: 22px;
  font-weight: 700;
}

.course-infos-top .fixed-wrap .con .timer i {
  font-style: normal;
}

.course-infos-top .fixed-wrap .con .timer i.first {
  visibility: hidden;
}

.course-infos-top .fixed-wrap .con .timer i,
.course-infos-top .fixed-wrap .con .timer span {
  color: #f01414;
}

.course-infos-top .fixed-wrap .con .time-price {
  display: flex;
  align-items: center;
}

.course-infos-top .fixed-wrap .con .time-price .discount .sale-price {
  font-size: 32px;
  color: #f01414;
  line-height: 32px;
  font-weight: 700;
  margin-left: 8px;
}

.course-infos-top .fixed-wrap .con .time-price .discount .ori-price {
  font-size: 14px;
  color: #93999f;
  font-weight: 700;
  line-height: 32px;
  margin-left: 8px;
  margin-right: 2px;
  text-decoration: line-through;
}

.course-infos-top .fixed-wrap .con .time-price .discount .sale-timer {
  padding: 6px 12px;
  background-color: #f90\9\0;
  background-image: linear-gradient(
    90deg,
    rgba(255, 0, 105, 0.99) 0,
    #ff7a00 99%
  );
  border-radius: 16px;
}

.course-infos-top .fixed-wrap .con .time-price .discount .sale-timer .name {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  line-height: 20px;
}

.course-infos-top .fixed-wrap .con .time-price .discount .sale-timer div {
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  margin-left: 8px;
}

.course-infos-top .fixed-wrap .con .time-price .discount .sale-timer span {
  background: #fff;
  border-radius: 4px;
  padding: 2px 2px;
  font-size: 12px;
  color: #383d42;
  text-align: center;
  line-height: 12px;
  font-weight: 700;
}

.course-infos-top .fixed-wrap .con .time-price .discount .sale-timer i {
  color: #fff;
  font-style: normal;
  padding: 0 1px;
}

.course-infos-top .fixed-wrap .con .time-price .no-discount .ori-price {
  font-size: 32px;
  color: #f01414;
  line-height: 32px;
  font-weight: 700;
}

.course-infos-top .fixed-wrap .con .time-price .can-huabei {
  margin-top: 4px;
  margin-left: 12px;
  line-height: 12px;
  padding: 6px 10px;
  background-color: #6c61d3\9\0;
  background-image: linear-gradient(90deg, #6c61d3 0, #53a0fd 100%);
  border-radius: 12px;
}

.course-infos-top .fixed-wrap .con .time-price .can-huabei a {
  font-size: 12px;
  color: #fff;
}

.course-infos-top .fixed-wrap .con .time-price .can-baitiao {
  margin-top: 4px;
  margin-left: 16px;
  line-height: 12px;
  padding: 6px 10px;
  background-image: linear-gradient(90deg, #ff323f 0, #f76469 100%);
  border-radius: 12px;
}

.course-infos-top .fixed-wrap .con .time-price .can-baitiao a {
  font-size: 12px;
  color: #fff;
}

.course-infos-top .fixed-wrap .con .time-price .has-coupon-20210618,
.course-infos-top .fixed-wrap .con .time-price .has-coupon-20211111 {
  margin-left: 22px;
  margin-top: 4px;
}

.course-infos-top .fixed-wrap .con .time-price .has-coupon-20210618 span,
.course-infos-top .fixed-wrap .con .time-price .has-coupon-20211111 span {
  width: 130px;
  height: 24px;
  background-image: url(/static/module/img/coupon_20210618.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  font-size: 12px;
  color: #f20d0d;
  line-height: 24px;
  font-weight: 600;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

.course-infos-top
  .fixed-wrap
  .con
  .time-price
  .has-coupon-20210618
  span:not(:first-child),
.course-infos-top
  .fixed-wrap
  .con
  .time-price
  .has-coupon-20211111
  span:not(:first-child) {
  margin-left: 8px;
}

.course-infos-top .fixed-wrap .con .time-price .has-coupon-20210618 span::after,
.course-infos-top
  .fixed-wrap
  .con
  .time-price
  .has-coupon-20211111
  span::after {
  content: " | 领劵";
}

.course-infos-top
  .fixed-wrap
  .con
  .time-price
  .has-coupon-20210618
  span.success,
.course-infos-top
  .fixed-wrap
  .con
  .time-price
  .has-coupon-20211111
  span.success {
  color: #ca5b1b;
  background-image: url(/static/module/img/coupon_20210618_success.png);
}

.course-infos-top
  .fixed-wrap
  .con
  .time-price
  .has-coupon-20210618
  span.success::after,
.course-infos-top
  .fixed-wrap
  .con
  .time-price
  .has-coupon-20211111
  span.success::after {
  content: " | 已领";
}

.course-infos-top .fixed-wrap .con .time-price .has-coupon {
  margin-top: 3px;
  margin-left: 12px;
  line-height: 12px;
  padding: 6px 10px;
  background-color: #f90\9\0;
  background-image: linear-gradient(-90deg, #ffad00 0, #ff5900 94%);
  border-radius: 12px;
}

.course-infos-top .fixed-wrap .con .time-price .has-coupon a {
  font-size: 12px;
  color: #fff;
}

.course-infos-top .fixed-wrap .con .time-price .moneyoff-info {
  margin-top: 3px;
  margin-left: 12px;
  font-size: 12px;
  line-height: 12px;
  padding: 5px 10px;
  border-radius: 12px;
  color: #f53d3d;
  border: 1px solid rgba(245, 61, 61, 0.6);
  position: relative;
}

.course-infos-top .fixed-wrap .con .time-price .icon-num {
  line-height: 20px;
  padding: 6px 12px;
  border-radius: 100px;
  color: #fff;
  background-image: linear-gradient(
    90deg,
    rgba(255, 0, 105, 0.99) 0,
    #ff7a00 99%
  );
  vertical-align: super;
}

.course-infos-top .fixed-wrap .con .time-price .discount-price {
  font-size: 32px;
  color: #f01414;
  line-height: 1;
  font-weight: 600;
  margin-left: 16px;
}

.course-infos-top .fixed-wrap .con .time-price .ori-price {
  margin-left: 8px;
  color: #93999f;
  vertical-align: super;
}

.course-infos-top .fixed-wrap .con .time-price .avatars {
  display: flex;
  align-items: center;
}

.course-infos-top .fixed-wrap .con .time-price .avatars li {
  width: 32px;
  height: 32px;
  padding: 1px;
  border-radius: 100px;
}

.course-infos-top .fixed-wrap .con .time-price .avatars li.have {
  background-image: linear-gradient(#ff046b 0, #ff7e08 99%);
}

.course-infos-top .fixed-wrap .con .time-price .avatars li + li {
  margin-left: 16px;
}

.course-infos-top .fixed-wrap .con .time-price .avatars li img {
  width: 100%;
  height: 100%;
  border-radius: 100px;
}

.course-infos-top .fixed-wrap .con .time-price .friend {
  margin-left: 24px;
  color: #545c63;
}

.course-infos-top .fixed-wrap .con .time-price .friend .imv2-group {
  margin-right: 4px;
}

.course-infos-top .fixed-wrap .con .time-price .friend i {
  color: #f01414;
  font-style: normal;
}

.course-infos-top .fixed-wrap .con .time-price .countdown {
  margin-left: 24px;
  color: #545c63;
}

.course-infos-top .fixed-wrap .con .time-price .countdown span {
  display: inline-block;
  color: #fff;
  width: 24px;
  line-height: 20px;
  font-size: 12px;
  text-align: center;
  background-image: linear-gradient(
    90deg,
    rgba(255, 0, 105, 0.99) 0,
    #ff7a00 99%
  );
  border-radius: 4px;
}

.course-infos-top .fixed-wrap .con .time-price .countdown .red {
  margin: 0 2px;
  font-style: normal;
  color: #f01414;
}

.course-infos-top .fixed-wrap .con .time-price .success {
  color: #f01414;
  font-size: 16px;
}

.course-infos-top .fixed-wrap .con .info-bar {
  margin-top: 12px;
}

.course-infos-top .fixed-wrap .con .info-bar span {
  font-size: 12px;
  color: #545c63;
  line-height: 24px;
  font-weight: 700;
  padding-right: 8px;
}

.course-infos-top .fixed-wrap .con .info-bar span.nodistance {
  padding-right: 0;
  font-weight: 400;
}

.course-infos-top .fixed-wrap .con .info-bar span.last {
  padding-right: 8px !important;
}

.course-infos-top .fixed-wrap .con .info-bar i {
  font-size: 16px;
  color: #545c63;
  line-height: 16px;
  padding: 0 4px;
  vertical-align: middle;
}

.course-infos-top .fixed-wrap .con .info-bar .adjust {
  background: #e2eaf1;
  border-radius: 12px;
  font-size: 12px;
  color: #383d42;
  line-height: 16px;
  padding: 4px 10px;
  font-weight: 400;
}

.course-infos-top .fixed-wrap .con .info-bar .adjust.nextLine {
  float: left;
  margin-top: 12px;
}

.course-infos-top .fixed-wrap .con .btns {
  position: absolute;
  top: 40px;
  right: 40px;
  font-size: 0;
}

.course-infos-top .fixed-wrap .con .btns a {
  display: inline-block;
}

.course-infos-top .fixed-wrap .con .btns .red-btn {
  background: #f01414;
  border-radius: 24px;
  font-size: 16px;
  color: #fff;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 700;
  padding: 12px 38px;
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.course-infos-top .fixed-wrap .con .btns .red-btn:hover {
  background-color: #f04646;
}

.course-infos-top .fixed-wrap .con .btns .disable-btn {
  background: #fff;
  box-shadow: 0 0 0 2px rgba(28, 31, 33, 0.1);
  border-radius: 24px;
  font-weight: 700;
  padding: 12px 34px;
  font-size: 16px;
  color: #d3d6d9;
  letter-spacing: 0;
  line-height: 24px;
  cursor: default;
}

.course-infos-top .fixed-wrap .con .btns .pink-btn {
  background: rgba(240, 20, 20, 0.1);
  border-radius: 24px;
  font-size: 16px;
  color: #f20d0d;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 700;
  padding: 12px 34px;
  margin-left: 18px;
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.course-infos-top .fixed-wrap .con .btns .pink-btn:hover {
  background-color: #f20d0d;
  color: #fff;
}

.course-infos-top .fixed-wrap .con .btns .gobuy {
  margin-right: 16px;
}

.course-infos-top .fixed-wrap .con .btns .addcart {
  background: rgba(240, 20, 20, 0.1);
  border-radius: 24px;
  font-size: 16px;
  color: #f20d0d;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: 700;
  padding: 12px 48px;
  position: relative;
  transition: all 0.2s;
  -moz-transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.course-infos-top .fixed-wrap .con .btns.spell-group {
  display: flex;
  align-items: center;
  border-radius: 100px;
  overflow: hidden;
}

.course-infos-top .fixed-wrap .con .btns.spell-group .red-group-btn,
.course-infos-top .fixed-wrap .con .btns.spell-group .yellow-group-btn {
  color: #fff;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 24px;
  font-weight: 600;
}

.course-infos-top
  .fixed-wrap
  .con
  .btns.spell-group
  .red-group-btn
  + .red-group-btn,
.course-infos-top
  .fixed-wrap
  .con
  .btns.spell-group
  .red-group-btn
  + .yellow-group-btn,
.course-infos-top
  .fixed-wrap
  .con
  .btns.spell-group
  .yellow-group-btn
  + .red-group-btn,
.course-infos-top
  .fixed-wrap
  .con
  .btns.spell-group
  .yellow-group-btn
  + .yellow-group-btn {
  margin-left: 1px;
}

.course-infos-top .fixed-wrap .con .btns.spell-group .yellow-group-btn {
  background-color: #f58e3e;
}

.course-infos-top .fixed-wrap .con .btns.spell-group .red-group-btn {
  background-color: #f01414;
}

.course-infos-top .fixed-wrap .con .group-qrcode {
  display: none;
  position: absolute;
  top: 110px;
  right: 40px;
  font-size: 12px;
  border-radius: 4px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 -8px 16px 0 rgba(7, 17, 27, 0.2);
  text-align: center;
  z-index: 1;
}

.course-infos-top .fixed-wrap .con .group-qrcode p {
  margin-bottom: 20px;
}
</style>
