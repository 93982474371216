<template>
  <div class="class30-main">
    <div class="direct-box clearfix">
      <div class="tab-item js-tab-item">
        <div @click="actitle = v.title" v-for="(v,i) in nrap" :key="i" class="tab js-jieduan-btn"  :class="{'active':v.title == actitle}"><a :href="`#${v.title}`" style="color:#000">阶段{{i+1}}</a></div>
      </div>
      <div class="right-item">
        <div v-for="(v,i) in nrap" :key="i" class="stage-section js-areatop js-section-0">
          <div :name="v.title" class="top">
            <div class="stage-name">{{v.title}}</div>
            <div class="stage-nums">
              27门课
              <em>/</em>课程总时长：50小时 <em>/</em>练习题：405
              <em>/</em>大作业：2 <em>/</em>考试：2 <em>/</em>教辅资料：68
            </div>
          </div>
        <div  v-for="(va,i) in v.listItem" :key="i" >
          <div  class="week-name">第{{i+1}}周 &nbsp;&nbsp;{{va.title}}</div>
          <div class="content">
            <div class="desc">{{va.content}}</div>
            <div class="line"></div>
            <div class="black-title">课程安排：</div>
            <div class="text">
              <ul>
                <li v-for="(vb,i) in va.list" :key="i" >{{i+1}} · {{vb}}</li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['nrap'],
  data(){
    return{
      active:"阶段1",
      actitle:"阶段1"
    }
  },
  created(){
    this.actitle = this.nrap[0].title;
  }
};
</script>

<style scoped>
@import url("../../assets/css/learnserve.css");

.direct-box {
    width: 1000px;
    margin: 0 auto 15px;
    padding: 48px 50px 34px 32px;
    box-sizing: border-box;
    background: #fff
}

.direct-box .tab-item {
    width: 84px;
    margin-right: 50px;
    float: left
}

.direct-box .tab-item .tab {
    width: 84px;
    height: 40px;
    background: #f3f5f6;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.1);
    border-radius: 4px;
    font-size: 14px;
    color: #717a84;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    transition: all .3s;
    -moz-transition: all .3s;
    -webkit-transition: all .3s;
    -o-transition: all .3s
}

.direct-box .tab-item .tab.active,.direct-box .tab-item .tab:hover {
    background: #9448eb;
    color: #fff
}

.direct-box .tab-item .tab:not(:last-child) {
    margin-bottom: 32px
}

.direct-box .right-item {
    width: 784px;
    float: right
}

.direct-box .right-item .stage-section {
    width: 100%
}

.direct-box .right-item .stage-section .top {
    padding-left: 36px;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 22px
}

.direct-box .right-item .stage-section .top .stage-name {
    overflow: hidden;
    position: relative;
    font-size: 18px;
    color: #1c1f21;
    text-align: left;
    line-height: 50px;
    font-weight: 700;
    margin-bottom: 8px
}

.direct-box .right-item .stage-section .top .stage-name.system30 {
    line-height: 50px
}

.direct-box .right-item .stage-section .top .stage-name .video-attamp-btn {
    position: absolute;
    display: inline-block;
    margin-left: 12px;
    margin-top: 8px;
    width: 110px;
    height: 30px;
    background: #fff;
    border: 1px solid #9448eb;
    border-radius: 4px
}

.direct-box .right-item .stage-section .top .stage-name .video-attamp-btn .left-btn {
    background-image: url(/static/module/marketpage2020/img/direct/video-play.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 2.5px;
    left: 8px;
    background-size: cover
}

.direct-box .right-item .stage-section .top .stage-name .video-attamp-btn .p {
    position: absolute;
    left: 37px;
    width: 64px;
    font-family: PingFangSC-Semibold;
    font-size: 16px;
    color: #9448eb;
    font-weight: 700;
    line-height: 30px;
    white-space: nowrap
}

.direct-box .right-item .stage-section .top .stage-nums {
    font-size: 12px;
    color: #717a84;
    line-height: 17px;
    text-align: left
}

.direct-box .right-item .stage-section .top .stage-nums em {
    font-style: normal;
    margin-left: 8px;
    margin-right: 12px
}

.direct-box .right-item .stage-section .week-name {
    padding-left: 36px;
    box-sizing: border-box;
    position: relative;
    font-size: 16px;
    color: #9448eb;
    text-align: left;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
    background: #f8fafc;
    border-radius: 12px
}

.direct-box .right-item .stage-section .week-name::before {
    content: '';
    position: absolute;
    left: 0;
    width: 24px;
    height: 24px;
    background-image: url(/static/module/marketpage2020/img/direct/week-icon.png);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat
}

.direct-box .right-item .stage-section .content {
    padding-left: 36px;
    position: relative;
    margin-bottom: 20px
}

.direct-box .right-item .stage-section .content::before {
    content: '';
    position: absolute;
    left: 11px;
    width: 2px;
    height: 100%;
    background-color: #dedfe0
}

.direct-box .right-item .stage-section .content .desc {
    font-size: 14px;
    color: #1c1f21;
    text-align: left;
    line-height: 24px;
    margin-bottom: 10px
}

.direct-box .right-item .stage-section .content .line {
    width: 631px;
    height: 1px;
    background-color: rgba(0,0,0,.1);
    margin-bottom: 10px
}

.direct-box .right-item .stage-section .content .black-title {
    font-size: 16px;
    color: #1c1f21;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 6px
}

.direct-box .right-item .stage-section .content .text {
    font-size: 14px;
    color: #1c1f21;
    line-height: 24px
}

.direct-box .right-item .video-trysee-btn {
    text-align: center;
    padding-top: 100px;
    padding-bottom: 20px;
    font-size: 18px
}

.direct-box .right-item .video-trysee-btn a {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #9447eb;
    text-align: center;
    line-height: 24px;
    background: rgba(148,71,235,.15);
    border-radius: 25.5px;
    width: 160px;
    line-height: 50px;
    height: 50px;
    display: inline-block
}

</style>