<template>
  <div>
    <div class="class30-main">
      <div class="user-evaluation">
        <div class="js-section-new" style="min-height: 20px">
          <div v-for="(v,i) in oder.userList" :key="i" class="section">
            <div class="evaluation">
              <div
                class="user-img"
                style="
                  background-image: url(http://img.mukewang.com/user/60fa30b80001fc0e09600960-100-100.jpg);
                "
                :style="{backgroundImage:`url(${v.img})`}"
              ></div>
              <div class="right-content">
                <div class="name">
                  {{v.username}}
                  <div class="evaluation-img good"></div>
                </div>
                <div class="content">{{v.content}}</div>
                <div class="tab-row">
                  <div v-for="(v1,i) in v.pageList" :key="i" class="tab">{{v1}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="p-text">
          <a
            class="load-more js-next-page"
            >点击加载更多评价({{oder.total}})</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['oder'],
    data(){
        return{
           
        }
    }
};
</script>

<style>
.user-evaluation {
  width: 1000px;
  margin: 0 auto 72px;
  padding: 0 24px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 12px;
  overflow: auto;
}

.user-evaluation .empty_tip {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  display: table;
  height: 100%;
  width: 100%;
  line-height: 2.5;
  color: rgba(28, 31, 33, 0.4);
  padding: 72px 0;
  box-sizing: border-box;
}

.user-evaluation .empty_tip > span {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  letter-spacing: 5px;
}

.user-evaluation .section {
  padding-left: 6px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.user-evaluation .section:last-child {
  margin-bottom: 27px;
}

.user-evaluation .section .top-tabs {
  padding-top: 12px;
  box-sizing: border-box;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.user-evaluation .section .top-tabs .text {
  width: 126px;
  height: 36px;
  white-space: nowrap;
  margin-right: 30px;
}

.user-evaluation .section .top-tabs .text p {
  font-size: 18px;
  color: #9448eb;
  letter-spacing: 0;
  text-align: center;
  line-height: 18px;
  font-weight: 700;
}

.user-evaluation .section .top-tabs .text .first {
  margin-bottom: 2px;
}

.user-evaluation .section .top-tabs .text .second {
  font-size: 14px;
  line-height: 16px;
}

.user-evaluation .section .top-tabs .tabs {
  max-height: 80px;
  overflow: hidden;
  -prefix-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
  align-content: space-between;
}

.user-evaluation .section .top-tabs .tabs .tab-item {
  padding: 7px 16px;
  box-sizing: border-box;
  background: #fff;
  border: 1px solid #d3d6d9;
  border-radius: 16px;
  font-size: 12px;
  line-height: 17px;
  color: #383d42;
  letter-spacing: 0;
  text-align: center;
  margin-right: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.user-evaluation .section .top-tabs .tabs .tab-item.active,
.user-evaluation .section .top-tabs .tabs .tab-item:hover {
  background: #9448eb;
  color: #fff;
  border: 1px solid #9448eb;
}

.user-evaluation .section .evaluation {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 0;
  box-sizing: border-box;
}

.user-evaluation .section .evaluation .user-img {
  border: 3px solid #fff1b6;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 30px;
  background-size: cover;
  background-position: center center;
}

.user-evaluation .section .evaluation .right-content {
  -prefix-box-flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -moz-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.user-evaluation .section .evaluation .right-content .name {
  display: inline-block;
  font-size: 16px;
  color: #1c1f21;
  line-height: 24px;
  margin-bottom: 10px;
  position: relative;
}

.user-evaluation .section .evaluation .right-content .name .evaluation-img {
  width: 36px;
  height: 36px;
  background-size: cover;
  background-position: center;
  position: absolute;
  right: -46px;
  bottom: 0;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .name
  .evaluation-img.good {
  background-image: url(/static/module/marketpage2020/img/evaluation-good.png);
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .name
  .evaluation-img.middle {
  background-image: url(/static/module/marketpage2020/img/evaluation-middle.png);
}

.user-evaluation .section .evaluation .right-content .name .evaluation-img.bad {
  background-image: url(/static/module/marketpage2020/img/evaluation-bad.png);
}

.user-evaluation .section .evaluation .right-content .time {
  display: inline-block;
  float: right;
  font-size: 12px;
  color: #d3d6d9;
  line-height: 24px;
}

.user-evaluation .section .evaluation .right-content .content {
  font-size: 14px;
  color: #1c1f21;
  text-align: justify;
  line-height: 24px;
  margin-bottom: 10px;
  word-break: break-all;
}

.user-evaluation .section .evaluation .right-content .tab-row {
  height: 31px;
  overflow: hidden;
}

.user-evaluation .section .evaluation .right-content .tab-row .tab {
  display: inline-block;
  padding: 7px 21px;
  box-sizing: border-box;
  background: #f3f5f6;
  border-radius: 16px;
  font-size: 12px;
  line-height: 17px;
  color: #545c63;
  letter-spacing: 0;
  text-align: center;
  margin-right: 16px;
}

.user-evaluation .section .evaluation .right-content .teacher-reply {
  margin-top: 18px;
  padding: 14px;
  background: #f4f5f7;
  border-radius: 6px;
  box-sizing: border-box;
  word-break: break-all;
}

.user-evaluation .section .evaluation .right-content .teacher-reply p {
  font-size: 14px;
  color: #1c1f21;
  text-align: justify;
  line-height: 24px;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-list
  .reply-img {
  width: 74px;
  height: 74px;
  border: 1px solid transparent;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  margin-right: 8px;
  margin-top: 8px;
  cursor: pointer;
  transition: all 0.3s;
  -moz-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-list
  .reply-img.curr {
  border: 1px solid #f20d0d;
}

.user-evaluation .section .evaluation .right-content .reply-img-preview {
  width: 670px;
  min-height: 377px;
  position: relative;
  margin-top: 8px;
  border: 1px solid #e3e3e3;
  box-sizing: border-box;
  cursor: pointer;
  display: none;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview.showpreview {
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: showPreview 0.3s infinite;
  -moz-animation: showPreview 0.3s infinite;
  -webkit-animation: showPreview 0.3s infinite;
  -o-animation: showPreview 0.3s infinite;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

.user-evaluation .section .evaluation .right-content .reply-img-preview img {
  height: auto;
  width: 100%;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  img.rotate90 {
  animation: rotate90 0.3s infinite;
  -moz-animation: rotate90 0.3s infinite;
  -webkit-animation: rotate90 0.3s infinite;
  -o-animation: rotate90 0.3s infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  img.rotate180 {
  animation: rotate180 0.3s infinite;
  -moz-animation: rotate180 0.3s infinite;
  -webkit-animation: rotate180 0.3s infinite;
  -o-animation: rotate180 0.3s infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  img.rotate270 {
  animation: rotate270 0.3s infinite;
  -moz-animation: rotate270 0.3s infinite;
  -webkit-animation: rotate270 0.3s infinite;
  -o-animation: rotate270 0.3s infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  img.rotate360 {
  animation: rotate360 0.3s infinite;
  -moz-animation: rotate360 0.3s infinite;
  -webkit-animation: rotate360 0.3s infinite;
  -o-animation: rotate360 0.3s infinite;
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  .rotate-img {
  width: 68px;
  height: 24px;
  position: absolute;
  right: 8px;
  top: 8px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  text-align: center;
  cursor: pointer;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  .rotate-img
  i {
  font-size: 16px;
  color: #fff;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  .rotate-img
  span {
  font-size: 14px;
  color: #fff;
  vertical-align: top;
  line-height: 26px;
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  .next-img {
  width: 42px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 42px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  display: none;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.user-evaluation
  .section
  .evaluation
  .right-content
  .reply-img-preview
  .prev-img {
  width: 42px;
  height: 48px;
  display: none;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -moz-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  font-size: 42px;
  color: #fff;
  text-align: center;
  line-height: 48px;
  text-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.user-evaluation .p-text {
  font-size: 14px;
  color: #b2b8bd;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
}

.user-evaluation .p-text a {
  font-size: 14px;
  color: #1c1f21;
  letter-spacing: 0;
  text-align: center;
  margin-left: 11px;
  display: inline-block;
  cursor: pointer;
}

.user-evaluation .p-text.first {
  margin-bottom: 29px;
}

.user-evaluation .p-text.second {
  margin-bottom: 29px;
  line-height: 38px;
  background: #f8fafc;
}

.user-evaluation .p-text.black {
  font-size: 14px;
  color: #1c1f21;
  letter-spacing: 0;
  text-align: center;
  margin: 0 auto 24px;
  display: none;
}

.user-evaluation .p-text.old-last {
  margin-bottom: 48px;
  line-height: 38px;
  background: #f8fafc;
}

.user-evaluation .load-more {
  display: inline-block;
  padding: 10px 15px;
  box-sizing: border-box;
  background: #efefef;
  border-radius: 20px;
  font-size: 14px;
  color: #1c1f21;
  letter-spacing: 0;
  text-align: center;
  line-height: 20px;
  margin: 0 auto 48px;
}

.user-evaluation .load-more.disabled {
  color: #b2b8bd;
}
</style>