<template>
  <div>
    <div class="class30-main service-container">
      <div
        class="left-menu-three js-left-menu left-menu-color"
        style="margin-top: -192px; display: block; opacity: 0.107342"
      >
        <div class="item js-menu js-menu-1" data-section="1">问答专区</div>
        <div class="item js-menu js-menu-2 active" data-section="2">
          作业专区
        </div>
        <div class="item js-menu js-menu-3" data-section="3">笔记专区</div>
        <div class="item js-menu js-menu-4" data-section="4">
          教材、教辅专区
        </div>
        <div class="back js-backTop">
          <i class="imv2-up2"></i>
          <span class="">返回顶部</span>
        </div>
      </div>

      <div class="padding64 section-1" id="section-1">
        <div class="wrap">
          <h2>问答专区：专业、及时、答你所疑</h2>
          <h5>p8课堂已累计回答学员 <i>316352</i> 个问题</h5>
          <div class="bg-box"></div>
        </div>
      </div>
      <div class="padding64 bg-gray section-2" id="section-2">
        <div class="wrap">
          <h2>作业专区：所见即所得，所学即所用</h2>
          <div class="bg-box"></div>
        </div>
      </div>

      <div class="padding64 section-3" id="section-3">
        <div class="wrap">
          <h2>笔记专区：边学边记，随用随查</h2>
          <h5>总结出专属于你自己的技术手册</h5>
          <div class="bg-box"></div>
        </div>
      </div>
      <div class="padding64 bg-gray section-4" id="section-4">
        <div class="wrap">
          <h2>教材、教辅专区：让你的学习更“方便”</h2>
          <div class="bg-box"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.service-container .section-1 .bg-box{
    background-image: url(https://class.imooc.com/static/module/marketpage2020/img/learnservice/section-1.png);
}
.service-container .section-2 .bg-box{
    background-image: url(https://class.imooc.com/static/module/marketpage2020/img/learnservice/section-2.png);
}
.service-container .section-3 .bg-box{
    background-image: url(https://class.imooc.com/static/module/marketpage2020/img/learnservice/section-3.png);
}
.service-container .section-4 .bg-box{
    background-image: url(https://class.imooc.com/static/module/marketpage2020/img/learnservice/section-4.png);
}
</style>
